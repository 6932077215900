var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.location'),
            icon: 'tim-icons icon-molecule-40',
            path:_vm.meters
            // '/meters'
          }}}),(_vm.admin)?_c('div',[_c('sidebar-item',{attrs:{"link":{  name: _vm.$t('sidebar.admin'),
          icon: 'tim-icons icon-components' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.list'), path: '/admin' }}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.map'),
              path: '/maps/full-screen'}}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.userreg'),
              path: '/invite-customers'}}}),_c('hr'),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.manreg'),
              path: '/managers'}}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.invitemanreg'),
              path: '/invite-managers'}}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.manper'),
              path: '/manager-permission'}}}),_c('hr'),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.busreg'),
              path: '/invite-business-managers'}}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.busmanper'),
              path: '/business-manager-permission'}}}),_c('hr'),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.build'),
              path: '/table-buildings'}}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.regbuild'),
              path: '/register-building'}}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('menu.subbuild'),
              path: '/register-building-subdivision'}}})],1)],1):_vm._e()],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }