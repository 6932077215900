import firebase from 'firebase';

// const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyBl1KKoS-yqWZRJDqIqH7EMf1Bwl2_vbcs",
    authDomain: "martinson-machine.firebaseapp.com",
    databaseURL: "https://martinson-machine.firebaseio.com",
    projectId: "martinson-machine",
    storageBucket: "martinson-machine.appspot.com",
    messagingSenderId: "815952598078",
    appId: "1:815952598078:web:a762ba24162b7f475f6d6f",
    measurementId: "G-P7XK7CVWFR"
};
firebase.initializeApp(config);
firebase.analytics();
// firebase.firestore().settings(settings);


export default firebase;
