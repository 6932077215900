import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userType: Cookies.get('userType') || null,
  },
  mutations: {
    setUserType(state, userType) {
      state.userType = userType
      Cookies.set('userType', userType)
    },
  },
  actions: {
    setUserType({ commit }, userType) {
      commit('setUserType', userType)
    },
  },
  getters: {
    getUserType: state => state.userType,
  },
})
