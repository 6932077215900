import VueRouter from 'vue-router';
import routes from './routes';
import Vue from 'vue';
import supabase from '@/supabase.js';

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const { data: { user } } = await supabase.auth.getUser();
  var usertype = '';
  const token = to.query.token;
  // console.log(from)
  // console.log('/// ')
  // console.log(to)
  console.log('token',token)
  if(to.meta.requiresToken){
    if(!token){
      next('/');
    }else {
      next();
    }
  }
  if (user) {
    usertype = user.user_metadata.userType;
    if (typeof to.meta.userType === 'string') {
      to.meta.userType = to.meta.userType.split(',');
    }
    if (typeof usertype === 'string') {
      usertype = usertype.split(',');
    }
    console.log('userType', usertype, 'meta UserType:',to.meta.userType);
    Vue.prototype.$user = Object.assign({}, Vue.prototype.$user, user);
    if (to.meta.userType) {
      if (!usertype.some(element => to.meta.userType.includes(element))) {
        next('/');
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    Vue.prototype.$user = { admin: false };
    if (requiresAuth) {
      next({ path: '/login' });
    } else {
      next();
    }
  }
});


export default router;
