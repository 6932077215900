/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
//const axios = require('axios')


 


// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
//import VueMqtt from 'vue-mqtt';

import Vuex from 'vuex';
Vue.use(Vuex);
import store from './store'


// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
//Vue.use(VueMqtt, 'mqtt://mqtt.martinsonmachine.com');
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}
// Vue.mixin({
//   methods: {
//     globalHelper: function () {
//       alert("Hello world")
//       axios
//   .get('https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a')
//   .then(res => {
//     console.log(`statusCode: ${res.statusCode}`)
//     console.log(res.data)
//   })
//   .catch(error => {
//     console.error(error)
//   })
//     },
//   },
// }) <div v-on:click="globalHelper">Click me</div>

/* eslint-disable no-new */
new Vue({
  store,
  el: '#app',
  render: h => h(App),
  
  router,
  i18n
}
);
