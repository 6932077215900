import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
import SetupLayout from 'src/pages/Pages/SetupLayout.vue';
//const fb = require('./../Firebase.js');
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
//import store from '../store'
// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue');
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

  const Rooms = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Rooms.vue');

  const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/List.vue');

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Buttons.vue');
const GridSystem = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/GridSystem.vue');
const Panels = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Panels.vue');
const SweetAlert = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/SweetAlert.vue');
const Notifications = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Notifications.vue');
const Icons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Icons.vue');
const Typography = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Typography.vue');

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
import Widgets from 'src/pages/Widgets.vue';

// Forms pages
const RegularForms = () => import('src/pages/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Forms/Wizard.vue');
const Managers = () => import ('src/pages/Tables/menagers.vue')

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/GoogleMaps.vue');
const FullScreenMap = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/FullScreenMap.vue');
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');

  const Settings = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Settings.vue');


  const How = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/How.vue');

const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/TimeLinePage.vue');
  const Comments = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Comments.vue');
const Login = () =>
   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const RTL = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');


  
// Customers
const LoginCustomers = () =>
  import('src/pages/Pages/logins/LoginCustomers.vue');
const RegisterCustomers = () =>
  import('src/pages/Pages/Register/RegisterCustomers.vue');


//Managers 
const LoginManagers = () =>
  import('src/pages/Pages/logins/LoginManagers.vue');
const RegisterManager = () =>
import('src/pages/Pages/Register/RegisterManagers.vue');


//Business Managers
const LoginBusinessManagers = () =>
import('src/pages/Pages/logins/LoginBusinessManagers.vue');
const RegisterBusinessManager = () =>
import('src/pages/Pages/Register/RegisterBusinessManagers.vue');
const RegisterBuilding = () =>
import ( 'src/pages/Pages/Register/RegisterBuilding.vue');
const RegisterBuildingSubdivision = () =>
import ( 'src/pages/Pages/Register/RegisterBuildingSubdivision.vue');
const TableBuildings= () => import ('src/pages/Tables/Buildings.vue')
const BusinessManagerPermission= () => import ('src/pages/Pages/permissions/BusinessManagerPermission.vue')
const ManagerPermission= () => import ('src/pages/Pages/permissions/ManagerPermission.vue')


// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/RegularTables.vue');
const ExtendedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/ExtendedTables.vue');
const AdminPage = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/PaginatedTables.vue');
  const Rank = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/Rank.vue');


  // Invitations 
  const InvitManager = () =>
  import('src/pages/Pages/Invitations/InviteManager.vue');
  const InvitBusinessManager = () =>
  import('src/pages/Pages/Invitations/InviteBusinessManager.vue');
  const InvitCustomers = () =>
  import('src/pages/Pages/Invitations/InviteCustomer.vue');

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      components: { default: Buttons }
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      components: { default: GridSystem },
      meta: {
        requiresAuth: true
      },

    },
    {
      path: 'panels',
      name: 'Panels',
      components: { default: Panels }
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      components: { default: SweetAlert }
    },
    {
      path: 'notifications',
      name: 'Notifications',
      components: { default: Notifications }
    },
    {
      path: 'icons',
      name: 'Icons',
      components: { default: Icons }
    },
    {
      path: 'typography',
      name: 'Typography',
      components: { default: Typography }
    }
  ]
};
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  name: 'Forms',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      components: { default: RegularForms }
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      components: { default: ExtendedForms },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      components: { default: ValidationForms }
    },
  ]
};

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  name: 'Tables',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      components: { default: RegularTables },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      components: { default: ExtendedTables },
      meta: {
        requiresAuth: true
      },
    },
    
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      components: { default: GoogleMaps },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      meta: {
        hideContent: true,
        hideFooter: true,
        requiresAuth: true
      },
      components: { default: FullScreenMap }
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      components: { default: VectorMaps }
    }
  ]
};

// let pagesMenu = {
//   path: '/pages',
//   component: DashboardLayout,
//   name: 'Pages',
//   redirect: '/pages/user',
//   children: [
//     {
//       path: 'user',
//       name: 'User Page',
//       components: { default: User },
//       meta: {
//         requiresAuth: true
//       },
//     },
//     {
//       path: 'settings',
//       name: 'Settings',
//       components: { default: Settings },
//       meta: {
//         requiresAuth: true
//       },
//     },
//     {
//       path: 'timeline',
//       name: 'Timeline Page',
//       components: { default: TimeLine },
//       meta: {
//         requiresAuth: true
//       },
//     },
//     {
//       path: 'comments/:msg',
//       name: 'Comments',
//       components: { default: Comments },
      
//       meta: {
//         requiresAuth: true
//       },
//     },
//     {
//       path: 'rtl',
//       name: 'RTL Page',
//       components: { default: RTL }
//     }
//   ]
// };

let pagesMenu = {
  path: '/posts',
  component: DashboardLayout,
  name: 'Posts',
  redirect: '/posts/timeline',
  children: [
    
    {
      path: 'timeline',
      name: 'Timeline Page',
      components: { default: TimeLine },
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'comments/:msg',
      name: 'Comments',
      components: { default: Comments },
      
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'rtl',
      name: 'RTL Page',
      components: { default: RTL }
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login-business-manager',
      name: 'Login Business Manager',
      component: LoginBusinessManagers,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/login-manager',
      name: 'Login Manager',
      component: LoginManagers,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/login-customer',
      name: 'Login Customer',
      component: LoginCustomers,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/register-customer',
      name: 'Register Customer',
      component: RegisterCustomers,
      meta: {
        requiresAuth: false,
        requiresToken: true
      }
    },
    {
      path: '/register-manager',
      name: 'Register Manager',
      component: RegisterManager,
      meta: {
        requiresAuth: false,
        requiresToken: true
      }
    },
    {
      path: '/register-business-manager',
      name: 'Register Business Manager',
      component: RegisterBusinessManager,
      meta: {
        requiresAuth: false,
        requiresToken: true
      }
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    {
      path: '/wizard',
      name: 'Wizard',
      components: { default: Wizard },
      meta: {
        requiresAuth: true
      },
    }
  ]
};

let setupPages = {
  path: '/',
  component: SetupLayout,
  name: 'Setup',
  children: [
    
    {
      path: '/setup',
      name: 'Setup',
      components: { default: Wizard },
      meta: {
        requiresAuth: true
      },
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  setupPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'user',
        name: 'User Page',
        components: { default: User },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'alarms',
        name: 'Alarms',
        components: { default: Settings },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: { default: Calendar }
      },
      {
        path: 'meters/:id?',
        name: 'Meters',
        components: { default: Charts },
        props: true,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'listmeters',
        name: 'List',
        components: { default: List },
        props: true,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'room/:id?/:client?',
        name: 'Room',
        components: { default: Rooms },
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets }
      },
      {
        path: 'rank',
        name: 'Rank',
        components: { default: Rank },
        meta: {
          requiresAuth: true
        },
      }
      ,{
        path: 'how',
        name: 'how',
        components: { default: How },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'admin',
        name: 'Management',
        components: { default: AdminPage }
        ,meta: {
          requiresAuth: true
        },
      },
      {
        path: 'managers',
        name: 'managers',
        components: { default: Managers }
        ,meta: {
          requiresAuth: true,
          userType: ['manager','business-manager']
        },
      },
      {
        path: 'invite-customers',
        name: 'Invite Customers',
        components: { default: InvitCustomers }
        ,meta: {
          requiresAuth: true,
          userType: ['manager','business-manager']
        },
      },
      {
        path: 'invite-managers',
        name: 'Invite Managers',
        components: { default: InvitManager }
        ,meta: {
          requiresAuth: true,
          userType: ['manager','business-manager']
        },
      },
      {
        path: 'invite-business-managers',
        name: 'Invite Business Managers',
        components: { default: InvitBusinessManager}
        ,meta: {
          requiresAuth: true,
          userType: ['business-manager']
        },
      },
      {
        path: 'table-buildings',
        name: 'Table Buildings',
        components: { default: TableBuildings}
        ,meta: {
          requiresAuth: true,
          userType: 'business-manager'
        },
      },
      {
        path: 'register-building',
        name: 'register building',
        components: { default: RegisterBuilding }
        ,meta: {
          requiresAuth: true,
          userType: 'business-manager'
        },
      },
      {
        path: 'register-building-subdivision',
        name: 'register building subdivision',
        components: { default: RegisterBuildingSubdivision }
        ,meta: {
          requiresAuth: true,
          userType: 'business-manager'
        },
      },
      {
        path: 'business-manager-permission',
        name: 'Business Manager Permission',
        components: { default: BusinessManagerPermission }
        ,meta: {
          requiresAuth: true,
          userType: 'business-manager'
        },
      },
      {
        path: 'manager-permission',
        name: 'Manager Permission',
        components: { default: ManagerPermission }
        ,meta: {
          requiresAuth: true,
          userType: ['business-manager','manager']
        },
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
