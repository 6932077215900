<template>
  <base-table :data="tableData" thead-classes="text-primary" :key="componentKey">
    <template slot-scope="{ row }">
      <!-- <td><base-checkbox v-model="row.done"></base-checkbox></td> -->
      <td>
        <p class="title">{{ row.name }}</p>
        <p class="text-muted">{{ row.text }}</p>
        <p v-if="row.save>0">
                ${{formatPrice(row.save)}}
              </p>
          <hr />
          <i class="ti-time" style="font-size:1em" >{{ timeConverter(row.timestamp)}}</i>
      </td>
      <td class="td-actions text-right">
        <!-- <el-tooltip
          content="Edit task"
          effect="light"
          :open-delay="300"
          placement="top"
        > -->
       
          <base-button
                   
                   class="like btn-link"
                   
                    size="sm"
                    icon
                  >
      
                     <router-link class="btn-link"  :to="{ name: 'Comments', params: {msg:row.key} }" >
                    <i v-if="row.inv" class="tim-icons icon-money-coins"></i>
                    <i v-if="row.inv==false" class="tim-icons icon-chat-33"></i>
                          </router-link>
                  </base-button>
                
        <!-- </el-tooltip> -->
      </td>
    </template>
  </base-table>
</template>
<script>

import { BaseTable } from '@/components';
import firebase from '../../Firebase';

export default {
  components: {
    BaseTable
  },
  data() {
    return {
         componentKey: 0,
       liked: [],
      tableData: []
    };
  },

  created() {

 firebase.database().ref('post')
    .orderByChild('timestamp').limitToLast(30).on("value", notes => {
      this.tableData= [];
     notes.forEach(note => {

// var liked=this.liked.includes(note.ref.key)?"success":"info";

this.tableData.push({

          key:note.ref.key,
          name:note.child("name").val(),
          inv: note.child("inv").val(),
          text: note.child("text").val(),
          save: note.child("save").val(),
           like: note.child("like").val(),
            // liked: liked,
          timestamp:note.child("timestamp").val(),
          icon:"tim-icons icon-alert-circle-exc",
          color:note.child("color").val()

})
     
     });
     // console.log( this.statsCards.length);
     this.tableData.reverse();

    });
//  this.tableData.forEach(async (post,index) => {
// let wasliked= await firebase.database().ref('users')
// .child("me")
// .child("liked").child(post.key).once("value");
//  this.tableData[index].liked = wasliked.numChildren()>0?"success":"info"
// if(wasliked.numChildren()>0){
// this.liked.push(post.key);
// }

//  });
 this.forceRerender();


  
},
methods:{
       forceRerender() {
      console.log(" Taks render ");
      this.componentKey += 1;
    },
       handleLike(index) {

if(this.liked.includes(index)){

var index1 = this.liked.indexOf(index);
this.liked.splice(index1, 1);

firebase.database().ref('post').child(index).update({
  like:firebase.database.ServerValue.increment(-1)

})
firebase.database().ref('users').child("me").child("liked").child(index).remove();

}else{


this.liked.push(index);
firebase.database().ref('post').child(index).update({
  like:firebase.database.ServerValue.increment(1)

})

firebase.database().ref('users').child("me").child("liked").child(index).set({
  like:1
})
}

      // swal.fire({
      //   title: `You liked ${row.name}`,
      //   buttonsStyling: false,
      //   icon: 'success',
      //   customClass: {
      //     confirmButton: 'btn btn-success btn-fill'
      //   }
      // });
    },
    checkliked(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
       formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
 color(color){
     var time = 'badge badge-pill badge-' ;

  switch (color) {
    case "info":
      time=time+"info";
      break;
      case "success":
      time=time+"success";
      break;
      case "danger":
      time=time+"danger";
      break;
  
    default:
      break;
  }
  return time;
},
 timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},

     }
};
</script>
<style></style>
