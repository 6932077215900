<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- <div class="search-bar input-group" @click="searchModalVisible1 = true">
       
        <base-button class="animation-on-hover" 
          type="success"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal1"
        
        >Post result</base-button>
   
      </div> -->

      <!-- <div class="search-bar input-group" @click="searchModalVisible2 = true">
       
        <base-button class="animation-on-hover" 
          type="warning"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal2"
        
        >Post Question</base-button>
   
      </div> -->


      <!-- <div class="search-bar input-group" @click="searchModalVisible3 = true">
       
        <base-button class="animation-on-hover" 
          type="info"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal3"
        
        >energy reading</base-button>
   
      </div> -->
      
      <div class="search-bar input-group" >
        
        <button
          class="btn btn-link"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal"
        >
          <!-- <i class="tim-icons icon-zoom-split"></i> // @click="searchModalVisible = true"-->
        </button>
       
      </div>
      
      <modal
        :show.sync="searchModalVisible"
        class="modal-search"
        id="searchModal"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="text"
          class="form-control"
          id="inlineFormInputGroup"
          placeholder="SEARCH"
        />
      </modal>

      <modal
        :show.sync="searchModalVisible1"
        class="modal-search"
        id="searchModal1"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="number"
          class="form-control"
          placeholder="Savings in $"
           @keypress="isNumber($event)"
        />
      <input
          
          v-model="comments"
          type="text"
          class="form-control"
          placeholder="Comments..."
         
        />
            <!-- <el-date-picker
           
              type="datetime"
              class="form-control"
              placeholder="Date & Time"
              v-model="dateTimePicker"
            >
            </el-date-picker> -->
         
        <base-button class="animation-on-hover" 
          type="success"
             
                @click.native="addresult(searchQuery)"
        >Post</base-button>
      </modal>

      <modal
        :show.sync="searchModalVisible2"
        class="modal-search"
        id="searchModal2"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="text"
          class="form-control"
          placeholder="How can we help you save more energy?"
        />
      
         
        <base-button class="animation-on-hover" 
          type="success"
             
                @click.native="addpost(searchQuery)"
        >Post</base-button>
      </modal>


      <modal
        :show.sync="searchModalVisible3"
        class="modal-search"
        id="searchModal3"
        :centered="false"
        :show-close="true"
      >
        <el-select 
        slot="header"
        class="select-success"
             placeholder="Select the meter"
             v-model="selects.meter">
    <el-option v-for="option in selects.meters"
               class="select-success"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
    </el-select>

        <input
        
          v-model="searchQuery"
          type="number"
          class="form-control"
          placeholder="Meter info in KWh"
          @keypress="isNumber($event)"/>

         
      
            <el-date-picker
           
              type="datetime"
              class="form-control"
              placeholder="Day of Measuring"
              v-model="dateTimePicker"
            >
            </el-date-picker>
         
        <base-button class="animation-on-hover" 
          type="success"
             
             
                @click.native="addread(searchQuery)"
        >Post</base-button>
      </modal>


      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
      >
        <template
          slot="title"
        >
          <div class="notification d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-sound-wave"></i>
          <p class="d-lg-none">Notifications</p>
        </template>
        <li class="nav-link" v-for="item in statsCards" :key="item.key">
          <a href="#" class="nav-item dropdown-item"
            >Measured {{item.total}} {{item.type}} - limit {{item.triger}} {{item.type}} at {{ timeConverter(item.created_at)}}</a>
        </li>
        <!-- <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">
            You have 5 more tasks</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item"
            >Your friend Michael is in town</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">
            Another notification</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">
            Another one</a>
        </li> -->
      </base-dropdown>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
          <!-- <div class="photo"><img src="img/mike.jpg" /></div> -->
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Menu</p>
        </template>
        <li class="nav-link">
          

          <router-link  class="nav-item dropdown-item" to="/user">Profile</router-link>
        </li>
        <li class="nav-link">
          
           <router-link  class="nav-item dropdown-item" to="/alarms">Alarms</router-link>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" @click="logout" class="nav-item dropdown-item">Log out</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>

//import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import firebase from '../../Firebase';
import supabase from '@/supabase.js'
//import moment from 'moment';
export default {
  components: {
     [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    SidebarToggleButton,
 //   CollapseTransition,
    BaseNav,
    Modal,
     [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    routeName() {
      var { name } = this.$route;
      if(name=='Room'){

        name  +=  ` ${this.$route.params.id}`
      }
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      statsCards: [
     
     ],
      selects: {
            meter: '',
            meters: [
            ]
          },
      uid:"",
      name:"",
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchModalVisible1: false,
      searchModalVisible2: false,
      searchModalVisible3: false,
      dateTimePicker: '',
      searchQuery: '',
      comments:''
    };
  },
  created() {

  

    
  this.uid = this.$user.id;

  //

  supabase
  .from('noti')
  .select('*')
  .eq('customer_id',this.uid)
  .order('id', { ascending: false })
        .limit(5).then(data=>{


        console.log('noti:',data);


data.data.forEach(element => {

  this.statsCards.push(
   {
total:(parseFloat(element.total)).toFixed(2),
created_at:element.created_at,
triger:element.triger,
type:element.type,
   }
); 

  
});

     
  
      });

//   firebase.database()
//     .ref('users').child(currentUser.uid).child("noti")
//     //.ref('manual').child("test")
//     .orderByChild('timestamp').limitToLast(5).once("value",info => {

// info.forEach(note => {

// //console.log("ALARM:"+note.child("triger").val())
//  this.statsCards.push(
//    {
// total:(parseFloat(note.child("total").val())).toFixed(2),
// timestamp:note.child("timestamp").val(),
// triger:note.child("triger").val(),
// type:note.child("type").val(),
//    }
// );  
  
//       });
//     });

// var user = {};
// user = this.$user;

// this.name = user.firstName+" "+user.lastName;

// if(user.meter){

// this.selects.meters.push({
// value:user.meter[0],
// label:user.meter[0]
// }); 
// }



  },
  methods: {
    async logout () {
      console.log("logout")
  try {
    
    const { error } = await supabase.auth.signOut()
    
    await firebase.auth().signOut()
    if (error) throw error
    // redirecionar para a página de login 
    this.$router.push('/login')
    // this.clearData()
  } catch (error) {
    console.error(error)
  }
},

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  ,
    showSwal(text){
console.log(text);
this.searchModalVisible1= false
this.searchQuery ="";
this.dateTimePicker = ""
    },

    addread(text){
console.log(text);
console.log(this.dateTimePicker);
this.searchModalVisible3= false

var dateyDate = new Date(this.dateTimePicker);

 firebase.database().ref('manual').child(this.selects.meter).push({
        "0_total": this.searchQuery,
        timestamp: dateyDate.valueOf()
      });

this.searchQuery ="";
this.dateTimePicker = ""
this.selects.meter ="";




    },
    timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},
    addresult(text){
console.log(text);
this.searchModalVisible1= false

var dateyDate = new Date();

 firebase.database().ref('post').push({
        uid: this.uid,
         name: this.name,
        text: this.comments,
        like: 0,
        comm:0,
         inv:true,
         save: this.searchQuery,
         icon:"tim-icons icon-money-coins",
        timestamp: dateyDate.valueOf(),
        color:"success"
      });

firebase.database().ref('users').child(this.uid).update({
  saved:firebase.database.ServerValue.increment(parseFloat(text))
})

firebase.firestore().collection("users").doc(this.uid).update({
   saved:firebase.firestore.FieldValue.increment(parseFloat(text))
})

this.searchQuery ="";
this.comments = "";
    },
    addpost(text){
console.log(text);
console.log(this.dateTimePicker);
this.searchModalVisible2= false

var dateyDate = new Date();

 firebase.database().ref('post').push({
       uid: this.uid,
        name: this.name,
        like: 0,
           comm:0,
         save: "",
         text: this.searchQuery,
         inv:false,
          icon:"tim-icons icon-chat-33",
        timestamp: dateyDate.valueOf(),
         color:"danger"
      });

this.searchQuery ="";
this.dateTimePicker = ""
    },



    
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
